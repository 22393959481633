






























import { Model } from 'vue-property-decorator';

import { Guid } from 'guid-typescript';
import Component, { mixins } from 'vue-class-component';
import SharedMixin from '@/mixins/SharedMixin';

import { EditExpertiseCommand, UserSkillResource, UserIdSkill, Skill } from '@/services/iResourceService';
import LookUpStore from '@/feature/Shared/LookUp/store';
import { ExpertiseStore } from '../store';

@Component({})
export default class PgaExpertisesSelectUsers extends mixins(SharedMixin)
{
    @Model("", { type: Object }) readonly Model!: EditExpertiseCommand;

    private isLoading = true;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private headers: Array<any> = [
        {
            text: "Full Name",
            align: "start",
            sortable: true,
            value: "FullName",
        },
        {
            text: "Skill",
            align: "start",
            sortable: false,
            value: "Skill",
            width: "50%",
        },
    ];

    get description() : string
    {
        return ExpertiseStore.ExpertiseDetails?.Description ?? "";
    }

    get listUsers() : Array<UserSkillResource>
    {
        const listUserSkills: Array<UserSkillResource> = new Array<UserSkillResource>(...ExpertiseStore.ExpertiseDetails?.Users);
        const listUserIdSkills: Array<Guid> = listUserSkills.map(u => u.Id);
        LookUpStore.Users.forEach(u =>
        {
            if(!listUserIdSkills.includes(u.Id))
                listUserSkills.push({ Id: u.Id, FullName: u.FullName, Skill: 0 })
        });
        return listUserSkills;
    }

    get selectedUsers() : Array<UserSkillResource>
    {
        const selectedUserIdSkills: Array<UserSkillResource> = new Array<UserSkillResource>();
        ExpertiseStore.ExpertiseSelectUsers.Command?.Users.forEach(us => selectedUserIdSkills.push({ Id: us.UserId, FullName: '', Skill: us.Skill }))
        return selectedUserIdSkills ?? new Array<UserSkillResource>();
    }
    set selectedUsers(value: Array<UserSkillResource>)
    {
        const userIdSkills: Array<UserIdSkill> = new Array<UserIdSkill>();
        value.forEach(u => userIdSkills.push({ UserId: u.Id, Skill: u.Skill }));

        ExpertiseStore.SET_EXPERTISE_SELECTUSERS_USERS(userIdSkills);
    }

    private getSkill(id: Guid) : Skill
    {
        const expertiseById = ExpertiseStore.ExpertiseSelectUsers.Command?.Users
            .find(es => es.UserId == id);
        console.log(expertiseById);
        return expertiseById?.Skill ?? Skill.Basic;
    }

    private showSkill(userId: Guid): boolean
    {
        return this.selectedUsers.map(u => u.Id).includes(userId);
    }

    private setSkill(userId: Guid, skill: Skill): void
    {
        const userIdSkill: UserIdSkill = 
        {
            UserId: userId,
            Skill: skill,
        };
        ExpertiseStore.SET_EXPERTISE_SELECTUSERS_SKILL(userIdSkill);
    }

    async created() : Promise<void>
    {
        this.isLoading = true;
        await LookUpStore.GetListUsers();
        this.isLoading = false;
    }
}
