













import { Component } from "vue-property-decorator";
import { Guid } from 'guid-typescript';

import { mixins } from "vue-class-component";
import SharedMixin from "@/mixins/SharedMixin";
import { SharedModule } from "@/feature/Shared/store";

import { ExpertiseStore, SelectUsersExpertiseModel } from './store';
import PgaExpertisesSelectUsers from './Components/PgaExpertisesSelectUsers.vue';

@Component({
    components:
    {
		PgaExpertisesSelectUsers,
	}
})

export default class ExpertisesPageSelectUsers extends mixins(SharedMixin)
{

	get ExpertisesSelectUsers() : SelectUsersExpertiseModel
	{
		return ExpertiseStore.ExpertiseSelectUsers;
	}

	async SelectUsers() : Promise<void>
	{
		try
		{
			const id: string = this.$route.params.id;
			await ExpertiseStore.SelectUsersExpertise(Guid.parse(id));
			this.$router.go(-1);
		}
		// eslint-disable-next-line no-empty
		catch(error) { }
	}

	undo() : void 
	{
		this.$router.go(-1);
    }
    
	async mounted() : Promise<void>
	{
		const id: string = this.$route.params.id;
		await ExpertiseStore.GetExpertiseDetails(Guid.parse(id));
		ExpertiseStore.InitExpertiseSelectUsers();
		if(!ExpertiseStore.ExpertiseDetails.Handleable)
		{
			const errorMessage = "Sorry, seems you are not authorized to handle this Expertise";
			SharedModule.SET_ALERT_ERROR_MESSAGE(errorMessage);
			SharedModule.SET_ALERT_IS_VISIBLE(true);
			this.$router.go(-1);
		}
	}
	
	beforeDestroy() : void
	{
		ExpertiseStore.ExpertiseEdit.Errors = new Map<string, Array<string>>();
	}
}
