import { VuexModule, Module, Mutation, getModule, Action } from 'vuex-module-decorators'
import store from '@/infrastructure/store'
import { Guid } from 'guid-typescript';

import pgaDiContainer from '@/App.container';
import IResourceService from '@/services/iResourceService';
import IOrderService from '@/services/iOrderService';
import { ListExpertises, ExpertiseModel, TechAreaModel, TechnologyTableModel, UserSkillResource, UserIdSkill,
        CreateExpertiseCommand, EditExpertiseCommand, SelectUsersExpertiseCommand, 
        CreateExpertiseResponse, EditExpertiseResponse, DeleteExpertiseResponse, SelectUsersExpertiseResponse } from '@/services/iResourceService';
        
import ResourceService from '@/services/ResourceService';
import OrderService from '@/services/OrderService';
import { SharedModule } from '@/feature/Shared/store';

export interface ITechAreas {
    TechAreasList: Array<TechAreaModel>;
}

export interface ExpertiseState
{
    ExpertisesTable: ListExpertises;
    ExpertiseDetails: ExpertiseModel | null;
    ExpertiseCreate: CreateExpertiseModel;
    ExpertiseEdit: EditExpertiseModel;
    ExpertiseDelete: DeleteExpertiseModel;
    ExpertiseSelectUsers: SelectUsersExpertiseModel;

    TechAreasDropDown: Array<TechAreaModel>;
    TechnologiesDropDown: Array<TechnologyTableModel>;
}

export interface CreateExpertiseModel
{
    Command: CreateExpertiseCommand | null;
    Response: CreateExpertiseResponse | null;
    Errors: Map<string, Array<string>>;
}

export interface EditExpertiseModel
{
    ViewModel: ExpertiseModel | null;
    Command: EditExpertiseCommand | null;
    Response: EditExpertiseResponse | null;
    Errors: Map<string, Array<string>>;
}

export interface DeleteExpertiseModel
{
    Id: Guid;
    Response: DeleteExpertiseResponse | null;
}

export interface SelectUsersExpertiseModel
{
    Command: SelectUsersExpertiseCommand | null;
    Response: SelectUsersExpertiseResponse | null;
}

@Module({ namespaced: true, dynamic: true, store, name: 'ExpertiseModule', })
class ExpertisesStore extends VuexModule implements ExpertiseState
{
    _resourceService: IResourceService = pgaDiContainer.get<IResourceService>(ResourceService);
    _orderService: IOrderService = pgaDiContainer.get<IOrderService>(OrderService);

    TechAreas: ITechAreas = {
        TechAreasList: new Array<TechAreaModel>()
    }

    public ExpertisesTable: ListExpertises = 
    {
        Expertises: new Array<ExpertiseModel>(),
    };

    public ExpertiseDetails: ExpertiseModel =
    {
        Id: Guid.createEmpty(),
        Code: 0,
        Description: '',
        TechAreas: new Array<TechAreaModel>(),
        Technologies: new Array<TechnologyTableModel>(),
        ModifiedBy:
        {
            Id: Guid.createEmpty(),
            FullName: '',
        },
        ModifiedDate: new Date(),
        Users: new Array<UserSkillResource>(),
        Handleable: false,
    };

    public ExpertiseCreate: CreateExpertiseModel =
    {
        Command: null,
        Response: null,
        Errors: new Map<string, Array<string>>()
    };

    public ExpertiseEdit: EditExpertiseModel =
    {
        ViewModel: null,
        Command: null,
        Response: null,
        Errors: new Map<string, Array<string>>()
    };

    public ExpertiseDelete: DeleteExpertiseModel =
    {
        Id: Guid.createEmpty(),
        Response: null,
    }

    public ExpertiseSelectUsers: SelectUsersExpertiseModel =
    {
        Command: null,
        Response: null,
    };

    public TechAreasDropDown: Array<TechAreaModel> = new Array<TechAreaModel>();
    public TechnologiesDropDown: Array<TechnologyTableModel> = new Array<TechnologyTableModel>();

    // DropDown TechAreas
    @Action({ rawError: true })
    public async GetTechAreasDropDown()
    {
        const techAreas = await this._orderService.GetAllTechAreasDropdownRestricted();
        this.SET_TECHAREAS_DROPDOWN(techAreas.TechAreas);
    }
    @Mutation
    public SET_TECHAREAS_DROPDOWN(techAreas: Array<TechAreaModel>)
    {
        this.TechAreasDropDown = techAreas;
    }
    // DropDown Technologies
    @Action({ rawError: true })
    public async GetTechnologiesDropDown()
    {
        const technologies = await this._resourceService.GetAllTechnologiesDropdown();
        this.SET_TECHNOLOGIES_DROPDOWN(technologies.Technologies);
    }
    @Mutation
    public SET_TECHNOLOGIES_DROPDOWN(technologies: Array<TechnologyTableModel>)
    {
        this.TechnologiesDropDown = technologies;
    }

    // List Expertises
    @Action({ rawError: true })
    public async GetAllExpertises()
    {
        const expertises = await this._resourceService.GetAllExpertises();
        this.SET_EXPERTISES(expertises);
    }
    @Mutation
    SET_EXPERTISES(expertises: ListExpertises) {
        this.ExpertisesTable = expertises;
    }

    // Details Expertise
    @Action({ rawError: true })
    public async GetExpertiseDetails(id: Guid)
    {
        SharedModule.SET_ISLOADING(true);
        const expertise: ExpertiseModel = await this._resourceService.GetExpertise(id);
        this.SET_EXPERTISE_DETAILS(expertise);
        SharedModule.SET_ISLOADING(false);
    }
    @Action({ rawError: true })
    public DropExpertiseDetails()
    {
        const resetExpertiseDetails: ExpertiseModel =
        {
            Id: Guid.createEmpty(),
            Code: 0,
            Description: '',
            TechAreas: new Array<TechAreaModel>(),
            Technologies: new Array<TechnologyTableModel>(),
            ModifiedBy:
            {
                Id: Guid.createEmpty(),
                FullName: '',
            },
            ModifiedDate: new Date(),
            Users: new Array<UserSkillResource>(),
            Handleable: false,
        };
        this.SET_EXPERTISE_DETAILS(resetExpertiseDetails);
    }
    @Mutation
    public SET_EXPERTISE_DETAILS(expertise: ExpertiseModel)
    {
        this.ExpertiseDetails = expertise;
    }
    @Mutation
    public SET_EXPERTISE_DETAILS_ID(id: Guid)
    {
        this.ExpertiseDetails.Id = id;
    }
    @Mutation
    public SET_EXPERTISE_DETAILS_DESCRIPTION(description: string)
    {
        this.ExpertiseDetails.Description = description;
    }
    @Mutation
    public SET_EXPERTISE_DETAILS_TECHAREAS(techAreas: Array<TechAreaModel>)
    {
        this.ExpertiseDetails.TechAreas = techAreas;
    }
    @Mutation
    public SET_EXPERTISE_DETAILS_TECHNOLGIES(technologies: Array<TechnologyTableModel>)
    {
        this.ExpertiseDetails.Technologies = technologies;
    }

    // Create Expertise
    @Action({ rawError: true })
    public async CreateExpertise(): Promise<CreateExpertiseResponse>
    {
        try {
            let response: CreateExpertiseResponse = { Id: Guid.createEmpty() };
            SharedModule.SET_ISLOADING(true);
            if (this.ExpertiseCreate.Command)
                response = await this._resourceService.CreateExpertise(this.ExpertiseCreate.Command);
            SharedModule.SET_ISLOADING(false);
            return response;
        }
        catch (error) {
            SharedModule.SET_ISLOADING(false);
            const errs = parseErrors(error);
            this.SET_EXPERTISE_CREATE_ERRORS(errs);

            throw error;
        }
    }
    @Action({ rawError: true })
    public InitExpertiseCreate()
    {
        const command: CreateExpertiseCommand =
        {
            Description: '',
            TechAreaIds: new Array<Guid>(),
            TechnologyIds: new Array<Guid>(),
        }

        this.SET_EXPERTISE_CREATE(command);
    }
    @Action({ rawError: true })
    public async DropExpertiseCreate()
    {
        this.SET_EXPERTISE_CREATE(null);
    }
    @Mutation
    public SET_EXPERTISE_CREATE(command: CreateExpertiseCommand | null)
    {
        this.ExpertiseCreate.Command = command;
    }
    @Mutation
    public SET_EXPERTISE_CREATE_DESCRIPTION(description: string)
    {
        if (this.ExpertiseCreate.Command)
            this.ExpertiseCreate.Command.Description = description;
    }
    @Mutation
    public SET_EXPERTISE_CREATE_TECHAREAIDS(techAreaIds: Array<Guid>)
    {
        if (this.ExpertiseCreate.Command)
            this.ExpertiseCreate.Command.TechAreaIds = techAreaIds;
    }
    @Mutation
    public SET_EXPERTISE_CREATE_TECHNOLOGYIDS(technologyIds: Array<Guid>)
    {
        if (this.ExpertiseCreate.Command)
            this.ExpertiseCreate.Command.TechnologyIds = technologyIds;
    }
    @Mutation
    public SET_EXPERTISE_CREATE_ERRORS(errs: Map<string, Array<string>>)
    {
        this.ExpertiseCreate.Errors = errs;
    }

    // Edit Expertise
    @Action({ rawError: true })
    public async EditExpertise() : Promise<EditExpertiseResponse>
    {
        try {
            let response: EditExpertiseResponse = { Id: Guid.createEmpty(), };
            SharedModule.SET_ISLOADING(true);
            if (this.ExpertiseEdit.Command)
                response = await this._resourceService.EditExpertise(this.ExpertiseEdit.Command.Id, this.ExpertiseEdit.Command);
            
            SharedModule.SET_ISLOADING(false);
            return response;
        }
        catch (error) {
            SharedModule.SET_ISLOADING(false);
            const errs = parseErrors(error);
            this.SET_EXPERTISE_EDIT_ERRORS(errs);

            throw errs;
        }
    }
    @Action({ rawError: true })
    public async GetExpertiseToEdit(id: Guid)
    {
        SharedModule.SET_ISLOADING(true);

        this.SET_EXPERTISE_EDIT_COMMAND(null);
        this.SET_EXPERTISE_EDIT_VIEWMODEL(null);

        const expertise: ExpertiseModel = await this._resourceService.GetExpertise(id);
        
        this.SET_EXPERTISE_EDIT_VIEWMODEL(expertise);
        this.InitExpertiseEdit();

        this.SET_EXPERTISE_EDIT_ID(expertise.Id);
        this.SET_EXPERTISE_EDIT_DESCRIPTION(expertise.Description);
        this.SET_EXPERTISE_EDIT_TECHAREAIDS(expertise.TechAreas.map(ta => ta.Id));
        this.SET_EXPERTISE_EDIT_TECHNOLOGYIDS(expertise.Technologies.map(t => t.Id));

        SharedModule.SET_ISLOADING(false);
    }
    @Action({ rawError: true })
    public InitExpertiseEdit()
    {
        const command: EditExpertiseCommand =
        {
            Id: Guid.createEmpty(),
            Description: '',
            TechAreaIds: new Array<Guid>(),
            TechnologyIds: new Array<Guid>(),
        }

        this.SET_EXPERTISE_EDIT_COMMAND(command);
    }
    @Mutation
    public SET_EXPERTISE_EDIT_COMMAND(command: EditExpertiseCommand | null)
    {
        this.ExpertiseEdit.Command = command;
    }
    @Mutation
    public SET_EXPERTISE_EDIT_VIEWMODEL(viewModel: ExpertiseModel | null)
    {
        this.ExpertiseEdit.ViewModel = viewModel;
    }
    @Mutation
    public SET_EXPERTISE_EDIT_ID(id: Guid)
    {
        if (this.ExpertiseEdit.Command)
            this.ExpertiseEdit.Command.Id = id;
    }
    @Mutation
    public SET_EXPERTISE_EDIT_DESCRIPTION(description: string)
    {
        if (this.ExpertiseEdit.Command)
            this.ExpertiseEdit.Command.Description = description;
    }
    @Mutation
    public SET_EXPERTISE_EDIT_TECHAREAIDS(techAreaIds: Array<Guid>)
    {
        if (this.ExpertiseEdit.Command)
            this.ExpertiseEdit.Command.TechAreaIds = techAreaIds;
    }
    @Mutation
    public SET_EXPERTISE_EDIT_TECHNOLOGYIDS(technologyIds: Array<Guid>)
    {
        if (this.ExpertiseEdit.Command)
            this.ExpertiseEdit.Command.TechnologyIds = technologyIds;
    }
    @Mutation
    public SET_EXPERTISE_EDIT_ERRORS(errs: Map<string, Array<string>>)
    {
        this.ExpertiseEdit.Errors = errs;
    }

    // Delete Expertise
    @Action({ rawError: true })
    public async DeleteExpertise(id: Guid): Promise<DeleteExpertiseResponse>
    {
        try {
            let response: DeleteExpertiseResponse = { Id: Guid.createEmpty() };
            SharedModule.SET_ISLOADING(true);
            this.SET_EXPERTISE_DELETE(id);

            if (this.ExpertiseDelete)
                response = await this._resourceService.DeleteExpertise(this.ExpertiseDelete.Id);
            
            SharedModule.SET_ISLOADING(false);
            return response;
        }
        catch (error) {
            SharedModule.SET_ISLOADING(false);

            const errs = parseErrors(error);
            const message = errs.get('id')?.map(e => e = `- ${e}`).join(" <br> ") ?? "";
            SharedModule.SET_ALERT_ERROR_MESSAGE(message);
            SharedModule.SET_ALERT_IS_VISIBLE(true);
            
            throw error;
        }
    }
    @Mutation
    public SET_EXPERTISE_DELETE(id: Guid)
    {
        this.ExpertiseDelete.Id = id;
    }

    // Select Users for Expertise
    @Action({ rawError: true })
    public async SelectUsersExpertise(id: Guid): Promise<SelectUsersExpertiseResponse>
    {
        try {
            let response: SelectUsersExpertiseResponse = { Id: Guid.createEmpty() };
            SharedModule.SET_ISLOADING(true);

            if (this.ExpertiseSelectUsers.Command)
                response = await this._resourceService.SelectUsersExpertise(id, this.ExpertiseSelectUsers.Command);
            
            SharedModule.SET_ISLOADING(false);
            return response;
        }
        catch (error) {
            SharedModule.SET_ISLOADING(false);

            throw error;
        }
    }
    @Action({ rawError: true })
    public InitExpertiseSelectUsers()
    {
        const command: SelectUsersExpertiseCommand =
        {
            Users: new Array<UserIdSkill>(),
        }

        const userIdSkills: Array<UserIdSkill> = new Array<UserIdSkill>();
        this.ExpertiseDetails.Users.forEach(u => userIdSkills.push({ UserId: u.Id, Skill: u.Skill }));

        this.SET_EXPERTISE_SELECTUSERS_COMMAND(command);
        this.SET_EXPERTISE_SELECTUSERS_USERS(userIdSkills);
    }
    @Mutation
    public SET_EXPERTISE_SELECTUSERS_COMMAND(command: SelectUsersExpertiseCommand)
    {
        this.ExpertiseSelectUsers.Command = command;
    }
    @Mutation
    public SET_EXPERTISE_SELECTUSERS_USERS(users: Array<UserIdSkill>)
    {
        if(this.ExpertiseSelectUsers.Command)
            this.ExpertiseSelectUsers.Command.Users = users;
    }
    @Mutation
    public SET_EXPERTISE_SELECTUSERS_SKILL(userIdSkill: UserIdSkill)
    {
        if(this.ExpertiseSelectUsers.Command)
            this.ExpertiseSelectUsers.Command.Users.forEach(us =>
                {
                    if(us.UserId == userIdSkill.UserId)
                        us.Skill = userIdSkill.Skill;
                });
    }
}

function parseErrors(error: any): Map<string, Array<string>> {
    const errs = new Map<string, Array<string>>();
    for (const [key, value] of Object.entries(error.response.data)) {
        const messages = value as Array<string>;
        errs.set(key, messages);
    }
    return errs;
}

export const ExpertiseStore = getModule(ExpertisesStore);